import { render, staticRenderFns } from "./Temperatures.vue?vue&type=template&id=886b9998&scoped=true"
import script from "./Temperatures.vue?vue&type=script&lang=js"
export * from "./Temperatures.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "886b9998",
  null
  
)

export default component.exports