export default {
  $vuetify: {
    badge: "Badge",
    calendar: {
      moreEvents: "{0} autres"
    },
    carousel: {
      ariaLabel: {
        delimiter: "Diapositive de carrousel {0} de {1}"
      },
      next: "Visuel suivant",
      prev: "Visuel précédent"
    },
    close: "Fermeture",
    dataFooter: {
      firstPage: "Première page",
      itemsPerPageAll: "Tous",
      itemsPerPageText: "Éléments par page :",
      lastPage: "Dernière page",
      nextPage: "Page suivante",
      pageText: "{0}-{1} de {2}",
      prevPage: "Page précédente"
    },
    dataIterator: {
      loadingText: "Chargement... Veuillez patienter",
      noResultsText: "Aucun enregistrement correspondant trouvé"
    },
    dataTable: {
      ariaLabel: {
        activateAscending: "Activer pour trier par ordre croissant.",
        activateDescending: "Activer pour trier par ordre décroissant.",
        activateNone: "Activez pour supprimer le tri.",
        sortAscending: "Trié par ordre croissant.",
        sortDescending: "Trié par ordre décroissant.",
        sortNone: "Non trié."
      },
      itemsPerPageText: "Lignes par page :",
      sortBy: "Trier par"
    },
    datePicker: {
      itemsSelected: "{0} sélectionné",
      nextMonthAriaLabel: "Mois prochain",
      nextYearAriaLabel: "Année prochaine",
      prevMonthAriaLabel: "Mois précédent",
      prevYearAriaLabel: "Année précédente"
    },
    fileInput: {
      counter: "{0} fichiers",
      counterSize: "{0} fichiers ({1} en tout)"
    },
    noDataText: "Pas de données disponibles",
    pagination: {
      ariaLabel: {
        currentPage: "Page actuelle Page {0}",
        next: "Page suivante",
        page: "Aller à la page {0}",
        previous: "Page précédente",
        wrapper: "Navigation dans la pagination"
      }
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    }
  },
  chart: {
    legend: {
      average: "Moyenne",
      hours: "Heures",
      max: "Max",
      pressure: "Pression",
      temperature: "Température"
    }
  },
  common: {
    actions: {
      add: "Ajouter",
      apply: "Appliquer",
      applyChanges: "Appliquer les modifications",
      cancel: "Annuler",
      close: "Fermeture",
      download: "Télécharger",
      edit: "Modifier",
      exportAsCSV: "Exporter au format csv",
      goBack: "Retourner",
      hide: "Masquer",
      move: "Déplacer",
      next: "Suivant",
      no: "Non",
      ok: "OK",
      openInNewWindow: "S'ouvre dans une nouvelle fenêtre",
      previous: "Précédent",
      refresh: "Rafraîchir",
      remove: "Supprimer",
      retry: "Réessayer",
      save: "Sauvegarder",
      show: "Afficher",
      switchToServicePortal: null,
      transfer: "Transférer",
      view: "Vue",
      yes: "Oui"
    },
    allRightsReserved: "Tous les droits sont réservés",
    dataTable: {
      headers: {
        actions: "Actions"
      }
    },
    default: "Défaut",
    dialogs: {
      confirmRemove: {
        confirm: "Êtes-vous sûr de vouloir supprimer le {0} ?",
        note: "Remarque ! Cette action ne peut pas être annulée.",
        title: "Supprimer {0}"
      }
    },
    enums: {
      day: {
        now: "Maintenant",
        today: "Aujourd'hui",
        yesterday: "Hier"
      },
      measurementSystem: {
        imperial: "Impérial",
        metric: "Métrique"
      },
      time: {
        hours: "Heures",
        minutes: "Minutes",
        seconds: "Secondes"
      }
    },
    error: {
      causedBy: "Causé par",
      details: "Détails",
      more: "Suite",
      request: "Demande"
    },
    exceptions: {
      error: "Erreur"
    },
    filters: {
      customRange: "Gamme personnalisée",
      filter: "Filtre",
      last: "Derniers",
      timeRange: "Intervalle de temps"
    },
    invalidUsage: "Utilisation non valide",
    language: "Langue",
    messages: {
      noDataToExport:
        "Il n'y a pas de données à exporter. Veuillez sélectionner une plage horaire différente.",
      recordsExported: "{number} enregistrements ont été exportés"
    },
    placeholders: {
      email: "Entrez l'adresse e-mail",
      selectDates: "Sélectionnez des dates"
    },
    saveSuceeded: "Les modifications ont été enregistrées",
    total: null,
    units: {
      day: "{count} jours | {count} jour | {count} jours",
      daysAgo:
        "il y a {count} jours | il y a {count} jour | il y a {count} jours",
      hour: "{count} heures | {count} heure | {count} heures",
      hourShort: "H",
      month: "{count} mois | {count} mois | {count} mois",
      second: "{count} secondes | {count} seconde | {count} secondes",
      week: "{count} semaines | {count} semaine | {count} semaines"
    },
    validation: {
      errors: {
        betweenInvalid: "La valeur doit être comprise entre {MIN} et {MAX}",
        commentMaxChars:
          "Le commentaire doit comporter 256 caractères au maximum",
        customerNoInvalid: "Veuillez saisir un numéro de client valide ",
        discountInvalid:
          "La valeur doit être numérique et comprise entre 0 et 100",
        emailInvalid: "Adresse e-mail invalide",
        emailLengthInvalid: "Longueur de l'adresse e-mail non valide.",
        engineHoursInvalid: "Heures moteur non valides",
        fieldIsRequired: "Obligatoire",
        fieldLengthBetween: "Doit être compris entre {MIN} et {MAX} caractères",
        fieldMustBeginWithWord: "Doit commencer par un caractère de mot",
        latitudeInvalid: "Latitude doit être un nombre compris entre -90 et 90",
        longitudeInvalid:
          "La longitude doit être un nombre compris entre -180 et 180",
        maxNumberOfCharacters: "Maximum {NUMBER} caractères",
        missingDigitInPassword:
          "Le mot de passe nécessite au moins un chiffre (123)",
        missingLowerCaseInPassword:
          "Le mot de passe nécessite au moins une lettre minuscule (abc)",
        missingSpecialCharInPassword:
          "Le mot de passe nécessite au moins un caractère spécial (!@#)",
        missingUpperCaseInPassword:
          "Le mot de passe nécessite au moins une lettre majuscule (ABC)",
        numericFieldInvalid:
          "La valeur doit être numérique et ne pas commencer par zéro",
        numericFieldLengthInvalid:
          "Veuillez saisir une valeur avec {MIN} à {MAX} chiffres",
        partNumberInvalid: "Référence non valide",
        passwordDoesNotMatch: "Les nouveaux mots de passe doivent correspondre",
        passwordTheSameAsOldOne:
          "Le nouveau mot de passe ne peut pas être le même que l'ancien mot de passe",
        passwordTooLong:
          "Le nombre maximum de caractères du mot de passe est de 50",
        passwordTooShort: "Le mot de passe doit avoir au moins 8 caractères",
        priceListIdInvalid:
          "La valeur doit commencer par 2 lettres majuscules et contenir 2 chiffres",
        seriaNoInvalid: "Numéro de série non valide",
        seriaNoItemExist: "Le numéro de série est utilisé",
        seriaNoListEmpty: "La liste des numéros de série ne doit pas être vide",
        transferCodeInvalid: "Code de transfert invalide"
      }
    }
  },
  login: {
    buttons: {
      labels: {
        sendPasswordResetEmail: "Envoyer"
      }
    },
    changePassword: "Changer le mot de passe",
    confirmNewPassword: "Confirmer le nouveau mot de passe",
    contactUs: "Contactez-nous",
    email: "E-mail",
    forgotPassword: "Mot de passe oublié",
    forgotPasswordQuestion: "Mot de passe oublié?",
    newPassword: "Nouveau mot de passe",
    oldPassword: "Ancien mot de passe",
    password: "Mot de passe",
    passwordHasChanged: "votre mot de passe a été changé",
    passwordResetCheckEmail:
      "Veuillez vérifier votre courrier électronique pour plus d'instructions sur la façon de réinitialiser votre mot de passe.",
    passwordResetDescription:
      "Entrez l'adresse e-mail vérifiée de votre compte utilisateur et nous vous enverrons un lien de réinitialisation de mot de passe.",
    passwordStrength: "Fiabilité du mot de passe",
    pleaseLoginWithNewPassword:
      "Veuillez vous connecter avec votre nouveau mot de passe",
    sessionHasExpired: "Votre connexion a expiré. Veuillez vous reconnecter.",
    signIn: "S'IDENTIFIER",
    signOut: "Se déconnecter"
  },
  mainMenu: {
    documents: "Documents",
    downloads: "Téléchargements",
    help: "Aide",
    home: "Accueil",
    messages: "Messages",
    webshop: "Boutique en ligne"
  },
  mapInfoWindow: {
    machineSerialNo: "Numéro de série",
    missingMachineImage: "Image du produit manquante"
  },
  messages: {
    title: "Messages"
  },
  store: {
    errors: {
      awsFailedToPutItem: "Impossible de placer l'élément dans AWS",
      couldNotConnectToBackendServer:
        "Impossible de se connecter au serveur principal",
      error: "Erreur",
      failedToAddItem: "Échec de l'ajout de l'élément",
      failedToDeleteUser: "Échec de la suppression de l'utilisateur",
      failedToFechItem: "Échec de la récupération de l'élément",
      failedToFetchUserDetails:
        "Échec de la récupération des détails de l'utilisateur",
      failedToSaveItem: "Échec de l'enregistrement de l'élément",
      failedToSendEmailNotification:
        "Échec de l'envoi de la notification par e-mail",
      failedToUpdateUserDetails:
        "Échec de la mise à jour des détails de l'utilisateur"
    }
  },
  termsOfService: {
    agreeAndContinue: "Accepter et continuer",
    agreeTerms: "J’accepte les {TERMS} et {POLICY}.",
    description:
      "Avant de pouvoir commencer à utiliser {APP_TITLE}, vous devez accepter et accepter les conditions d'utilisation et la politique de confidentialité.",
    privacyPolicy: "Politique de confidentialité",
    title: "Conditions d'utilisation"
  }
}
