<template>
  <v-select
    id="language-control"
    class="language-control"
    v-model="selectedLanguage"
    dense
    outlined
    flat
    hide-details
    :items="languages"
    :item-value="item => item.code"
    :item-text="item => item.name"
  >
  </v-select>
</template>

<script>
import languages from "@/utils/languages"

export default {
  name: "LanguageSelect",
  data() {
    return {
      languages: languages.supported
    }
  },
  computed: {
    selectedLanguage: {
      get: function() {
        return this.$store.getters.langCode
      },
      // setter
      set: function(newValue) {
        this.$root.setLanguage(newValue)
        if (this.$route.name !== "login") {
          window.location.reload()
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
.language-control {
  max-width: 170px;
}
</style>
