<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          background-color="white"
          color="black accent-4"
          left
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#measurements">
            <v-icon class="mr-2" small>mdi-chart-line</v-icon>
            {{ $t("machines.tabHeaders.measurements") }}
          </v-tab>

          <v-tab v-if="canEditMeasurements" href="#settings">
            <v-icon class="mr-2" small>mdi-cogs</v-icon>
            {{ $t("machines.tabHeaders.settings") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <!-- MEASUREMENTS -->
      <v-tab-item value="measurements">
        <!-- Loading overlay -->
        <v-row v-if="isLoadingMeasurements"
          ><v-col cols="12"
            ><v-overlay absolute
              ><v-progress-circular
                indeterminate
              ></v-progress-circular></v-overlay></v-col
        ></v-row>
        <!-- Pickers -->
        <v-row class="mt-2">
          <v-col cols="12" sm="4" lg="5">
            <v-select
              v-model="selectedDataSources"
              item-value="id"
              :item-text="item => $t(item.text)"
              :items="dataSources"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('machines.filters.dataSource')"
              multiple
              @blur="fetchMeasurements()"
            >
            </v-select>
          </v-col>
          <v-col cols="6" sm="4" lg="3">
            <v-select
              :items="timeRangeItems"
              item-value="value"
              item-text="text"
              v-model="selectedTimeRange"
              :label="$t('common.filters.timeRange')"
            ></v-select>
          </v-col>
          <v-col cols="6" sm="4" lg="3">
            <v-menu
              ref="datePickerMenu"
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  readonly
                  :placeholder="$t('common.placeholders.selectDates')"
                  :disabled="selectedTimeRange !== 'custom'"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datePickerMenu = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  :disabled="dates[0] === undefined || dates[1] === undefined"
                  @click="
                    $refs.datePickerMenu.save(dates)
                    onSavePickedDate()
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1">
            <v-row align="center" justify="end">
              <v-col>
                <v-menu>
                  <template
                    v-slot:activator="{ on: menu }"
                    v-if="canDownloadCsv"
                  >
                    <v-btn
                      :disabled="selectedDataSources == 0"
                      absolute
                      right
                      icon
                      v-on="menu"
                      class="measurements-menu"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="canDownloadCsv" @click="onDownloadCsv()">
                      <v-list-item-title class="body-2">
                        <v-icon small>mdi-file-delimited</v-icon
                        >{{ $t("common.actions.exportAsCSV") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Charts -->
        <v-row>
          <v-col cols="12">
            <span v-for="dataSource in dataSources" :key="dataSource.id">
              <v-row v-if="dataSource.active">
                <v-col>
                  <v-component
                    :is="dataSource.component"
                    :machine="machine"
                    :dates="chartDates"
                    :measurementsData="measurementsData"
                  ></v-component>
                </v-col>
              </v-row>
              <v-divider v-if="dataSource.active"></v-divider>
            </span>
            <p v-if="selectedDataSources == 0">
              Please select one or more data sources.
            </p>
          </v-col>
        </v-row>
      </v-tab-item>
      <!-- SETTINGS -->
      <v-tab-item value="settings" v-if="canEditMeasurements">
        <MeasurementsSettings :machine="machine" />
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import EngineHours from "@/components/charts/EngineHours"
import Power from "@/components/charts/Power"
import RunningReducedTime from "@/components/charts/RunningReducedTime"
import ToolActiveTime from "@/components/charts/ToolActiveTime"
import HydraulicFluidPressure from "@/components/charts/HydraulicFluidPressure"
import Temperatures from "@/components/charts/Temperatures"
import MeasurementsSettings from "@/views/machine/Settings"
import Exporter from "@/utils/csvexport"

export default {
  name: "MachineMeasurements",
  props: ["machine"],
  components: {
    EngineHours,
    Power,
    RunningReducedTime,
    ToolActiveTime,
    HydraulicFluidPressure,
    Temperatures,
    MeasurementsSettings
  },
  data() {
    return {
      tab: "measurements",

      dates: [],

      datePickerMenu: false,

      selectedTimeRange: "today",
      dateRangeText: "",
      selectedDataSources: [],
      snackbar: false,
      snackbarText: ""
    }
  },
  created() {
    this.setDateRange()
    if (this.$route.query.show !== undefined) {
      this.selectedDataSources = [this.$route.query.show]
      this.fetchMeasurements()
    }
  },
  computed: {
    dataSources() {
      // M3 - Hydraulics temp
      let temperatureParams = ["M3"]

      if (this.isSmartPowerPlus) {
        // M150 - Ambient temperature
        temperatureParams.push("M150")
      }

      let sources = [
        {
          active: false,
          component: "Temperatures",
          id: "temperatures",
          text: "machines.measurements.temperatures",
          parameterIds: temperatureParams
        }
      ]
      if (this.hasPowerMeasurement) {
        // Voltage L1-L3: M131,M132,M133
        // Current L1-L3: M134,M135,M136
        // Frequency: M137
        let powerParams = [
          "M131",
          "M132",
          "M133",
          "M134",
          "M135",
          "M136",
          "M137"
        ]
        if (!this.canSeeEngineCurrent) {
          // Only show the voltage measurements
          powerParams = ["M131", "M132", "M133", "M137"]
        }
        sources.push({
          active: false,
          component: "Power",
          id: "power",
          text: "machines.measurements.power",
          parameterIds: powerParams
        })
      }
      sources.push(
        ...[
          {
            active: false,
            component: "EngineHours",
            id: "engine-hours",
            text: "machines.measurements.engineHours",
            parameterIds: ["C1"] // Engine seconds
          },
          {
            active: false,
            component: "ToolActiveTime",
            id: "tool-active-time",
            text: "machines.activities.toolActiveTime",
            parameterIds: ["C3"] // Tools active time
          },
          {
            active: false,
            component: "HydraulicFluidPressure",
            id: "hydraulic-fluid-pressure",
            text: "machines.measurements.hydraulicFluidPressure",
            parameterIds: ["M4"] // Hydraulics pressure
          }
        ]
      )
      return sources
    },
    units() {
      return this.$store.getters.userDetails?.preferences?.units || "metric"
    },
    timeRangeItems() {
      return [
        { text: this.$t("common.enums.day.today"), value: "today" },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            7
          )}`,
          value: "7 days"
        },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            30
          )}`,
          value: "30 days"
        },
        { text: this.$t("common.filters.customRange"), value: "custom" }
      ]
    },
    isLoadingMeasurements() {
      return this.$store.getters.loadingMeasurements
    },
    measurementsData() {
      const data = this.$store.getters.measurements

      // Modify the date so it looks like local time.
      // This is needed since Apex chart displays date time as UTC,
      // and there's no option to tell it to use local time.
      for (let ix = 0; ix < data.length; ix++) {
        const utcTime = new Date(data[ix].receiveTime)

        // Fake the local time by adding the time zone offset to the UTC time.
        // E.g.: Stockholm is GMT+2 incl. DST, thus gets a timezone offset of -120 minutes.
        // The minutes are converted to milliseconds, i.e. mult with 60000.
        const localTime = new Date(
          utcTime.getTime() - 60000 * utcTime.getTimezoneOffset()
        )
        data[ix].receiveTime = localTime.toISOString()
      }
      return data
    },
    canReadMeasurements() {
      return this.$store.getters.canReadMeasurements
    },
    canEditMeasurements() {
      return this.$store.getters.canEditMeasurements
    },
    canDownloadCsv() {
      return true
    },
    isSmartPowerPlus() {
      return this.machine.properties?.tags?.includes("smart_power_plus")
    },
    canSeeEngineCurrent() {
      return this.$store.getters.isService || this.$store.getters.isAdmin
    },
    hasPowerMeasurement() {
      // Power measurement only for electrical Smart Power Plus machines
      return this.isSmartPowerPlus && !this.machine.hasDieselEngine
    },
    chartDates() {
      let startDate = new Date(this.dates[0])
      let endDate = new Date(this.dates[1])
      let elapsed = endDate.getTime() - startDate.getTime() // elapsed time in milliseconds

      if (elapsed < 3600000) {
        // When date selection is today the elapsed time is zero,
        // so we set the time range to the entire day instead.
        startDate = new Date(this.dates[0] + "T00:00:00")
        endDate = new Date(this.dates[0] + "T23:59:59")
      }
      return [startDate, endDate]
    }
  },
  methods: {
    setDateRange() {
      let startDate = new Date()
      let offset = 0

      if (this.selectedTimeRange === "today") {
        offset = 0
      } else if (this.selectedTimeRange === "7 days") {
        offset = 6 // Becomes 7 since today will be included
      } else if (this.selectedTimeRange === "30 days") {
        offset = 29 // Becomes 30 since today will be included
      }

      startDate.setDate(startDate.getDate() - offset)
      this.dates = [
        startDate.toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10)
      ]
    },
    fetchMeasurements() {
      const selectedParameters = this.dataSources
        .filter(ds => ds.active)
        .map(ds => ds.parameterIds)
        .flat()

      if (selectedParameters.length == 0) {
        return
      }

      const queryParameters = {
        tenantUid: this.$route.params.tenantUid,
        serialNo: this.$route.params.serialNo,
        start: this.dates[0],
        end: this.dates[1],
        parameters: selectedParameters
      }

      this.$store.dispatch("fetchMeasurements", queryParameters)
    },
    onSavePickedDate() {
      this.datePickerMenu = false
      this.dateRangeText = this.dates.join(" ~ ")
      this.fetchMeasurements()
    },
    onDownloadCsv() {
      if (this.measurementsData.length > 0) {
        let parameters = []

        this.dataSources.forEach(dataSource => {
          if (dataSource.active) {
            parameters.push(...dataSource.parameterIds)
          }
        })

        let numRecords = Exporter(this.$i18n, this.units).exportMeasurements(
          this.machine,
          this.measurementsData,
          parameters
        )
        this.snackbarText = this.$t("common.messages.recordsExported", {
          number: numRecords
        })
        this.snackbar = true
      } else {
        var dialog = {
          message: this.$t("common.messages.noDataToExport"),
          buttons: [
            {
              label: this.$t("common.actions.ok"),
              action: () => {
                return false
              }
            }
          ]
        }
        this.$store.dispatch("setGenericDialog", dialog)
      }
    }
  },
  watch: {
    tab(newTab) {
      if (newTab === "measurements") {
        this.fetchMeasurements()
      }
    },
    selectedTimeRange(newRange) {
      if (newRange !== "custom") {
        this.setDateRange()
        this.fetchMeasurements()
      } else {
        // Display date picker. OK callback will trigger fetch
        this.datePickerMenu = true
      }
    },
    dates(newDates) {
      // Always set earliest date as start date
      if (newDates[0] > newDates[1]) {
        newDates.reverse()
      }
      this.dateRangeText = newDates.join(" ~ ")
    },
    selectedDataSources(dataSourceIds) {
      this.dataSources.forEach(dataSource => {
        dataSource.active = dataSourceIds.includes(dataSource.id)
      })
    }
  }
}
</script>
<style type="scss">
#measurements .apexcharts-toolbar {
  z-index: 1;
}
.measurements-menu {
  z-index: 1;
}
</style>
