<template>
  <div v-if="enabled">
    <apexchart
      type="area"
      height="400"
      :options="chartOptions"
      :series="series"
      v-if="measurementsData"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import MeasurementsFactory from "@/utils/measurementsfactory"

export default {
  props: ["machine", "dates", "measurementsData"],
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      enabled: false
    }
  },
  computed: {
    units() {
      return this.$store.getters.userDetails?.preferences?.units || "metric"
    },
    yaxis() {
      let y = []
      if (this.canSeeCurrent) {
        y.push(
          {
            // By using the same series name the scale will be the same for current
            seriesName: `${this.$t("machines.measurements.current")} L1`,
            title: {
              text: `${this.$t("machines.measurements.current")} (A)`
            }
          },
          {
            // By using the same series name the scale will be the same for current
            seriesName: `${this.$t("machines.measurements.current")} L1`,
            show: false
          },
          {
            // By using the same series name the scale will be the same for current
            seriesName: `${this.$t("machines.measurements.current")} L1`,
            show: false
          }
        )
      }
      y.push(
        ...[
          {
            // By using the same series name the scale will be the same for voltage
            seriesName: `${this.$t("machines.measurements.voltage")} L1`,
            title: {
              text: `${this.$t("machines.measurements.voltage")} (V)`
            }
          },
          {
            // By using the same series name the scale will be the same for voltage
            seriesName: `${this.$t("machines.measurements.voltage")} L1`,
            show: false
          },
          {
            // By using the same series name the scale will be the same for voltage
            seriesName: `${this.$t("machines.measurements.voltage")} L1`,
            show: false
          },
          {
            seriesName: this.$t("machines.measurements.frequency"),
            title: {
              text: `${this.$t("machines.measurements.frequency")} (Hz)`
            },
            opposite: true
          }
        ]
      )
      return y
    },
    chartOptions() {
      return {
        chart: {
          fontFamily: "Roboto, sans-serif",
          height: 350,
          animations: {
            enabled: false
          },
          toolbar: {
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: true,
              pan: false,
              reset: "<i class='v-icon mdi mdi-overscan'></i>"
            }
          }
        },
        theme: {
          palette: "palette6" // https://apexcharts.com/docs/options/theme/
        },
        noData: {
          text: this.$i18n.t("$vuetify.noDataText")
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "stepline",
          width: 4
        },
        title: {
          text: this.$t("machines.measurements.power"),
          align: "left",
          floating: false
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: "datetime",
          // Add 24h prior to and 24h after the selected time range,
          // so that all data points are  properly displayed.
          min: new Date(this.dates[0]).getTime() - 24 * 3600 * 1000,
          max: new Date(this.dates[1]).getTime() + 24 * 3600 * 1000
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy HH:mm:ss"
          }
        },
        yaxis: this.yaxis,
        legend: {
          showForSingleSeries: true,
          position: "top",
          horizontalAlign: "center",
          floating: this.$vuetify.breakpoint.mobile ? false : true,
          offsetY: this.$vuetify.breakpoint.mobile ? null : -25,
          offsetX: this.$vuetify.breakpoint.mobile ? null : -5
        }
      }
    },
    series() {
      let seriesConf = []
      if (this.machine.hasDieselEngine) {
        return seriesConf
      }

      if (this.canSeeCurrent) {
        seriesConf.push(
          ...[
            {
              name: `${this.$t("machines.measurements.current")} L1`,
              parameterId: "M134",
              data: []
            },
            {
              name: `${this.$t("machines.measurements.current")} L2`,
              parameterId: "M135",
              data: []
            },
            {
              name: `${this.$t("machines.measurements.current")} L3`,
              parameterId: "M136",
              data: []
            }
          ]
        )
      }
      seriesConf.push(
        ...[
          {
            name: `${this.$t("machines.measurements.voltage")} L1`,
            parameterId: "M131",
            data: []
          },
          {
            name: `${this.$t("machines.measurements.voltage")} L2`,
            parameterId: "M132",
            data: []
          },
          {
            name: `${this.$t("machines.measurements.voltage")} L3`,
            parameterId: "M133",
            data: []
          }
        ]
      )
      seriesConf.push({
        name: this.$t("machines.measurements.frequency"),
        parameterId: "M137",
        data: []
      })

      let measurementSeries = MeasurementsFactory.makeAreaSeries(
        this.measurementsData,
        seriesConf,
        rawValue => {
          return Number(rawValue).toFixed(0)
        }
      )
      return measurementSeries
    },
    canSeeCurrent() {
      return this.$store.getters.isService || this.$store.getters.isAdmin
    }
  },
  mounted() {
    this.enabled = true
  },
  watch: {
    dates() {
      // Hack to fix issue with xaxis min max not updating on date change
      this.enabled = false
      setTimeout(() => {
        this.enabled = true
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
