import { loadLanguageCookie } from "../store/users/cookiejar"

class LanguageUtils {
  static defaultLang() {
    return LanguageUtils.supported[0]
  }
  static getName = code =>
    LanguageUtils.supported.filter(l => l.code == code).map(l => l.name)[0]
  static getSupportedLanguageCode(country) {
    const lang = LanguageUtils.supported.filter(l => l.country == country)
    return lang.length > 0 ? lang[0].code : loadLanguageCookie()
  }
}

LanguageUtils.supported = [
  { country: "us", code: "en", name: "English" },
  { country: "uk", code: "en", name: "English" },
  { country: "de", code: "de", name: "Deutsch" },
  { country: "ch", code: "de", name: "Deutsch" },
  { country: "fr", code: "fr", name: "Francais" },
  { country: "it", code: "it", name: "Italiano" },
  { country: "es", code: "es", name: "Español" }
]

export default LanguageUtils
