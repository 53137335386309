import Vue from "vue"
import Amplify from "aws-amplify"
import i18n from "i18n"
import { Auth, Storage } from "aws-amplify"
import App from "./App.vue"
import AppAlert from "./components/AppAlert.vue"
import DataFilter from "./filters/datafilter"
import TemperatureFilter from "./filters/temperaturefilter"
import PressureFilter from "./filters/pressurefilter"
import EngineHourFilter from "./filters/enginehourfilter"
import EventFilter from "./filters/eventfilter"
import LocaleDateFilter from "./filters/localedatefilter"
import OptionalTextFilter from "./filters/optionaltextfilter"
import TimeFilter from "./filters/timefilter"
import TruncateTextFilter from "./filters/truncatetextfilter"
import "./plugins/clipboard"
import "./plugins/i18n"
import vuetify from "./plugins/vuetify"
import router from "./router"
import { store } from "./store"
import pinDirective from "./utils/pin-directive"
import LanguageUtils from "./utils/languages"

const awsConfig = {
  aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID
}

const DEFAULT_LANG = LanguageUtils.defaultLang().code

Amplify.configure(awsConfig)

Auth.configure({
  identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
  region: process.env.VUE_APP_AWS_PROJECT_REGION
})

Storage.configure({
  AWSS3: {
    bucket: process.env.VUE_APP_S3_STORAGE_BUCKET,
    region: process.env.VUE_APP_AWS_PROJECT_REGION
  }
})

Vue.prototype.$bus = new Vue() //it is used to communicate between multiple instances of Vue

Vue.config.productionTip = false

Vue.component("app-alert", AppAlert)

Vue.directive("pin", pinDirective)

Vue.filter("localeDateFilter", LocaleDateFilter)
Vue.filter("optionalTextFilter", OptionalTextFilter)
Vue.filter("eventFilter", EventFilter)
Vue.filter("timeFilter", TimeFilter)
Vue.filter("dataFilter", DataFilter)
Vue.filter("engineHourFilter", EngineHourFilter)
Vue.filter("truncateTextFilter", TruncateTextFilter)
Vue.filter("temperatureFilter", TemperatureFilter)
Vue.filter("pressureFilter", PressureFilter)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.authToken !== null
    }
  },
  created() {
    if (this.$store.getters.loggedInUser) {
      this.fetchData()
    }
  },
  beforeMount() {
    this.$vuetify.lang.t = (key, ...params) => this.$i18n.t(key, params)
    ;(this.$store.state.$i18n = this.$i18n),
      this.$i18n.loadLanguageFiles(
        this.$i18n.fallbackLocale?.substring(0, 2) || DEFAULT_LANG
      ) //Load fallback locale (en)
  },
  methods: {
    setLanguage(langCode) {
      this.$i18n.loadLanguageFiles(langCode)
      this.$i18n.locale = langCode
      this.$vuetify.lang.current = this.$i18n.locale
      this.$store.dispatch("setLanguage", langCode)

      if (this.$store.getters.userDetails && this.$store.getters.loggedInUser) {
        if (
          (this.$store.getters.userDetails.preferences?.language ||
            DEFAULT_LANG) != langCode
        ) {
          this.$store
            .dispatch("updateUserPreferences", { language: langCode })
            .catch(() => {
              // Error already displayed by dispatched action.
            })
        }
      }
    },
    async fetchData() {
      if (this.$route.query.logout) {
        return //Do not load anything if we are going to logout
      }

      const dataLangCode = this.$store.getters.dataLangCode

      this.$store.dispatch("loadEventMetadata", dataLangCode)
      this.$store.dispatch("loadMeasurementMetadata", dataLangCode)
      this.$store.dispatch("loadProductMetadata", dataLangCode)
      this.$store.dispatch("loadProductGroupMetadata", dataLangCode)

      // Only load parameter metadata if the user can read parameters.
      if (this.$store.getters.canReadParameters) {
        this.$store.dispatch("loadParameterMetadata", dataLangCode)
      }

      // Only load role metadata if the user can read users.
      if (this.$store.getters.canReadUsers) {
        this.$store.dispatch("loadRoleMetadata", dataLangCode)
      }

      let tenantTreeLoaded = true
      try {
        await this.$store.dispatch("loadTenantTree")
      } catch {
        // Error already displayed by dispatched action.
        tenantTreeLoaded = false
      }
      if (!tenantTreeLoaded) {
        return // We can't load anything more when tenant tree not loaded properly
      }

      this.$store.dispatch(
        "setSelectedTenant",
        this.$store.getters.tenantTree[0]
      )

      this.$store.dispatch("fetchMachines", this.$store.getters.tenantRoot.uid)

      await this.$store.dispatch("loadMessages", dataLangCode)

      await this.$store.dispatch("fetchUserDetails", {
        tenantUid: this.$store.getters.loggedInUser.tenantUid,
        uid: this.$store.getters.loggedInUser.uid
      })
      this.setLanguage(this.$store.getters.langCode || DEFAULT_LANG)
    }
  },
  watch: {
    isUserLoggedIn() {
      if (this.$store.getters.loggedInUser) {
        this.fetchData()
      }
    }
  }
}).$mount("#app")
