import Vue from "vue"
import VueI18n from "vue-i18n"
import LanguageUtils from "../utils/languages"

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en"
})
let languagesLoaded = []

VueI18n.prototype.loadLanguageFiles = function(langCode) {
  this.loadLanguageFilesFromPath(
    [
      "",
      "views/documents",
      "views/downloads",
      "views/home",
      "views/tenants",
      "views/machines",
      "views/users",
      "views/reports",
      "views/fleetAnalytics"
    ],
    langCode
  )
}

VueI18n.prototype.loadLanguageFilesFromPath = function(paths, langCode) {
  let loadLanguage = (langCode, path) => {
    if (!languagesLoaded.includes(`${langCode}-${path}`)) {
      let translations = require(`@/locales/${
        path ? path + "/" : ""
      }${langCode}.js`).default
      this.mergeLocaleMessage(langCode, translations)
      languagesLoaded.push(`${langCode}-${path}`)
    }
  }
  langCode = langCode || this.locale?.substring(0, 2)
  const fallbackLangCode =
    this.fallbackLocale?.substring(0, 2) || LanguageUtils.defaultLang().code
  for (var i = 0; i < paths.length; i++) {
    loadLanguage(langCode, paths[i])
    if (langCode != fallbackLangCode) {
      loadLanguage(fallbackLangCode, paths[i]) //Fallback language loaded in case when there is missing translation for specific item
    }
  }
}

export default i18n
