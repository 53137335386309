export default {
  machines: {
    actions: {
      addMachine: "Register machine",
      clearProductFilters: "Clear product filters",
      importMachine: "Import machine",
      provisioned: "Provisioned",
      transfer: "Transfer",
      unprovisioned: "Unprovisioned"
    },
    activities: {
      beltActiveTime: "Belt active time",
      otherTime: "Other time",
      toolActiveTime: "Tool active time"
    },
    alarms: {
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              category: "Category",
              description: "Description",
              value: "Value"
            }
          }
        }
      },
      moreExists: "more exists"
    },
    dataTable: {
      headers: {
        active: "Active",
        brokkConnect: "Brokk Connect",
        category: "Category",
        code: "Code",
        date: "Date",
        description: "Description",
        device: "Device",
        engineHours: "Engine Hours",
        extraInfo: "Extra Info",
        id: "Id",
        lastSeen: "Last Seen",
        name: "Name",
        number: "Number",
        product: "Product",
        serviceAgreement: "Service Agreement",
        serviceStatus: "Service Status",
        severity: "Severity",
        status: "Status",
        tenantName: "Tenant",
        updateInterval: "Update Interval"
      },
      tooltips: {
        cellular: "Brokk Connect uses Cellular network",
        overdue: "Overdue",
        pending: "Pending",
        service: "Service",
        serviceAgreementMissing: "Service agreement not present",
        serviceAgreementPresent: "Service agreement present",
        unknown: "Unknown",
        unprovisioned: "Brokk Connect not fitted",
        upcoming: "Ok",
        wifi: "Brokk Connect uses Wi-Fi network"
      }
    },
    dialogs: {
      addMachine: {
        addMachineSucceededText: "The machine Brokk {machine} has been added.",
        confirmAddMachine: "Do you want to add the machine?",
        confirmAddMachineProceedInfo:
          "To add the machine select a model and press Next.",
        confirmAddMachineProceedInfoNext: "To add the machine press Next.",
        confirmAddMachineTitle: "Add machine?",
        engineHours: "Engine hours",
        enterEngineHoursTitle: "Enter engine hours",
        enterMachineInformationTitle: "Enter machine's information",
        enterserialNoDescription: "Enter a valid serial number",
        enterserialNoTitle: "Enter machine's serial number",
        errors: {
          couldNotAddMachine: "{msg}",
          couldNotRegisterService: "{msg}",
          couldNotUpdateService: "{msg}",
          noSuchserialNo: "Error: No such serial number"
        },
        serialNo: "Serial number",
        steps: {
          confirm: "Confirm",
          overview: "Overview",
          summary: "Summary"
        },
        title: "Add unprovisioned machine"
      },
      addServiceSchedule: {
        comment: "Service comment",
        commentHint:
          "The service comment will be visible in the service history",
        commentPlaceholder: "Enter service comment",
        engineHours: "Engine hours",
        enterEngineHoursTitle: "Enter engine hours",
        service2: "Service 2",
        service3: "Service 3",
        service4: "Service 4",
        serviceInformationTitle: "Enter service information",
        serviceTitle: "Service",
        steps: {
          confirm: "Confirm",
          overview: "Overview",
          summary: "Summary"
        },
        title: "Add service schedule"
      },
      commissioning: {
        saved: "Commissioning successfully saved.",
        steps: {
          applications: {
            hint: "(Optional) Select one or more applications",
            name: "Applications"
          },
          category: {
            name: "Category",
            otherCategoryHint: "Please specify other category",
            otherCategoryPlaceholder: "Other category"
          },
          commissioning: {
            description:
              "Please choose the date when the machine was commissioned. This will affect the warranty's start and end dates.",
            name: "Commissioning"
          },
          confirm: {
            description:
              "Please confirm that the following details are correct:",
            name: "Confirm",
            proceedInfo: "To commission the machine press Save."
          },
          segment: {
            description:
              "Please choose a segment and a category that describes how the machine will be used.",
            name: "Segment"
          }
        },
        title: "Commission machine"
      },
      editMachine: {
        createdTimestamp: "Created",
        description: "Description",
        descriptionPlaceholder: "Enter description",
        name: "Name",
        namePlaceholder: "Enter name",
        revision: "Revision",
        serialNo: "Serial No",
        tenant: "Tenant",
        tenantHint: "The tenant cannot be changed while a transfer is pending",
        tenantPlaceholder: "Select tenant",
        title: "Edit machine"
      },
      editServiceSchedule: {
        engineHours: "Engine hours",
        enterEngineHoursTitle: "Enter engine hours",
        service2: "Service 2",
        service3: "Service 3",
        service4: "Service 4",
        serviceInformationTitle: "Enter service information",
        serviceTitle: "Service",
        steps: {
          confirm: "Confirm",
          overview: "Overview",
          summary: "Summary"
        },
        title: "Edit service schedule"
      },
      editSettings: {
        cantEdit: "This parameter can't be edited",
        category: "Category",
        description: "Description",
        descriptionPlaceholder: "Enter description",
        device: "Device",
        id: "Id",
        number: "Number",
        operatorMustConfirm:
          "Your changes will need approval from an operator before taking effect",
        title: "Edit Update Interval",
        updateInterval: "Update Interval"
      },
      importMachine: {
        confirmTransferMachine:
          "Do you want to transfer the {machine} with serial number {sn} to the current tenant {tenant}?",
        confirmTransferProceedInfo:
          "To transfer the machine to this tenant press Next.",
        confirmTransferTitle: "Transfer machine?",
        enterTransferCodeDescription:
          "Enter a transfer code to move a machine from another tenant to the currently selected tenant",
        enterTransferCodeTitle: "Enter transfer code",
        errors: {
          couldNotCompleteTransfer:
            "Error: Could not complete the transfer. {msg}",
          noSuchTransferCode: "Error: No such transfer code",
          transferCodeAlreadyUsed:
            "Error: The transfer code has already been used",
          transferCodeExpired: "Error: The transfer code is expired",
          transferReverted: "Error: The transfer has been reverted"
        },
        steps: {
          confirm: "Confirm",
          overview: "Overview",
          summary: "Summary"
        },
        title: "Transfer machine to {tenant}",
        transferCode: "Transfer code",
        transferCodePlaceholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        transferSucceededText:
          "The {machine} with serial number {sn} has been transferred to {tenant}."
      },
      transferMachine: {
        errors: {
          internalError:
            "A transfer code could not be generated at this time due to an internal error.",
          machineAlreadyTransferred:
            "This machine was transferred to another tenant",
          pendingTransfer:
            "This machine has a pending transfer which was created",
          transferCodeExpires: "The transfer code expires"
        },
        refreshTheList: "Please refresh the machines list.",
        steps: {
          confirm: {
            description:
              "Transfer ownership of machine {sn} ({productName}) currently owned by {tenant}?",
            includeHistoricalDataInfo:
              "The transfer will include historical data.",
            name: "Confirm",
            notIncludeHistoricalDataInfo:
              "The transfer will not include historical data.",
            proceedInfo:
              "To generate a transfer code and initiate the transfer press Next.",
            title: "Confirm"
          },
          dataRetention: {
            description:
              "By including the machine's historical data in the transfer you allow the recipient to view all historic alarms, events and measurements including geolocation.",
            includeHistoricalData: "Include historical data in the transfer.",
            name: "Data retention",
            title: "Include history"
          },
          summary: {
            description:
              "Copy the below transfer code and send it via email to the recipient, who will use it to complete the transfer.",
            name: "Summary",
            title: "Transfer code"
          },
          transferOwnership: {
            description:
              "Transfer ownership of this machine to another tenant using a transfer code. The machine will remain accessible as long as the transfer is pending.",
            name: "Overview",
            title: "Transfer ownership"
          }
        },
        title: "Transfer Machine",
        transferAutomaticRevertInfo:
          "The transfer will automatically be reverted if the recipient does not complete the transfer within 3 days.",
        transferCode: "Transfer code"
      }
    },
    dieselParticulateFilter: {
      info: {
        error: "Contact Brokk Service.",
        ok: "Status OK.",
        title: "Diesel Particulate Filter",
        warning: "Manual regeneration needed. See display on machine."
      },
      status: {
        ashLevel: "Ash level",
        sootLevel: "Soot level",
        title: "DPF Status"
      }
    },
    engineHoursSelectionList: {
      lastDay: "Last Day",
      lastMonth: "Last Month",
      lastWeek: "Last Week",
      total: "Total"
    },
    featureNotAvailable:
      "This feature is currently not enabled on this machine",
    filters: {
      dataSource: "Data source"
    },
    info: {
      order: {
        commissioningDate: "Date of Commissioning",
        commissioningHint: "Max 6 months from delivery date",
        deliveryDate: "Delivery date",
        label: "Machine order",
        orderNumber: "Order number",
        orderType: "Order type"
      },
      segment: {
        actions: {
          commission: "Commission this machine"
        },
        applications: "Applications",
        category: "Category",
        label: "Segment",
        overlay: {
          description:
            "Commission the machine to activate its warranty, and define what kind of applications it will be used in.",
          disabledDescription:
            "Commissioning is possible once the machine has a delivery date and warranty length.",
          heading: "This is a stock machine"
        }
      },
      warranty: {
        endDate: "End date",
        label: "Warranty",
        length: "Length",
        startDate: "Start date"
      }
    },
    labels: {
      activeAlarms: "Active alarms",
      ambientTemperature: "Ambient temp",
      elMotorTemp: "El motor temp",
      engineHours: "Engine Hours",
      engineHoursDays: "Engine Hours / days",
      fuelLevel: "Fuel level",
      highPressureTime: "High pressure time",
      highTempTime: "High temp time",
      hydraulicFluidTemp: "Hydraulic fluid temp",
      machineActivity: "Machine activity",
      nextServiceIn: "Next service in",
      noMachines: {
        descriptionHasAccess:
          "Press the button below to register an unprovisioned machine. This gives you immediate access to the correct documentation and spare parts.",
        descriptionNoAccess:
          "By registering your machine, you get immediate access to the correct documentation and spare parts. Contact your nearest dealer to register your machine.",
        title: "Register a machine"
      },
      notification: "Notification"
    },
    location: {
      currentMachineSettings: "Current machine settings",
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              propertyCategory: "Category",
              propertyName: "Name",
              propertyValue: "Value"
            }
          }
        }
      },
      events: {
        dataTable: {
          headers: {
            date: "Date",
            description: "Description",
            extraData: "Extra info",
            source: "Source"
          }
        }
      },
      geofencingSettings: {
        actions: {
          add: "Add geofence",
          edit: "Edit geofence settings",
          remove: "Remove geofence settings"
        },
        confirm: {
          description:
            " Are you sure you want to apply the geofence changes? This may affect the machine's ability to operate. Please ensure that the machine is in a position that will not cause any damage nor harm to people or equipment.",
          title: "Apply Geofence"
        },
        coordinates: "Coordinates",
        description: " ",
        latitude: "Latitude",
        latitudePlaceholder: "Enter latitude",
        limit: {
          description: "",
          limitation: "Limitation",
          limitationPlaceholder: "Select limitation to add",
          title: "Limitations"
        },
        longitude: "Longitude",
        longitudePlaceholder: "Enter longitude",
        messages: {
          anyLimitationOrNotificationRequired:
            "Please specify one or more notifications",
          disabled: "The geofence feature is not enabled on this machine.",
          noLimitationsAdded: "No limitations added yet.",
          settingsNotSynched:
            "Geofence settings have not been synchronized by the machine.",
          settingsRemoved: "Geofence has been removed",
          settingsSaved: "Geofence has been saved"
        },
        notifications: {
          description:
            "Enter email addresses of people who should receive email notifications when the machine enters into or exits out of the geofence area.",
          title: "Notifications"
        },
        radius: "Radius",
        radiusPlaceholder: "Enter radius",
        title: "Geofence",
        triggers: {
          enter: "Enter",
          exit: "Exit"
        },
        unit: "Unit",
        unitPlaceholder: "Enter unit"
      },
      geolocationSettings: {
        actions: {
          edit: "Edit geolocation settings",
          remove: "Remove geolocation settings"
        },
        description: "Offline geolocation tracking settings",
        distance: "Distance",
        distanceHint:
          "Min distance the machine must move before sending an update",
        distancePlaceholder: "Enter distance",
        interval: "Interval",
        intervalHint: "Min time interval that must pass between updates",
        intervalPlaceholder: "Select interval",
        messages: {
          disabled:
            "The geolocation feature is currently not enabled on this machine.",
          settingsNotSynched:
            "Geolocation settings have not been synchronized by the machine.",
          settingsRemoved: "Geolocation settings has been removed",
          settingsSaved: "Geolocation settings has been saved"
        },
        movement: "Movement",
        timer: "Timer",
        title: "Geolocation settings",
        unit: "Unit",
        unitPlaceholder: "Select unit"
      },
      location: {
        actions: {
          setGeofencingLocation: "Set geofence location here"
        },
        description:
          "Define an area where the machine should operate and get notifications when it enters or exits the area.",
        messages: {
          changeGeofencingCoordinatesTip:
            "Use the 'Draw a circle' button on the map to define or change the machine's geofence."
        },
        showHistory: "Show location history",
        title: "Location settings"
      },
      map: {
        geofenceCenter: "Geofence center",
        machineLocation: "Machine location"
      }
    },
    maintenance: {
      service: {
        add: "The service was successfully added",
        beforeNextServiceYearlyService: "Before next service / yearly service",
        commentMaxChars: "Comment must be 2000 characters or less",
        overdue: "Services overdue",
        reset: {
          actions: {
            send: "Send service reset command"
          },
          availableForServiceUsersOnly:
            "The service reset feature is only available to service users.",
          comment: "Service comment",
          commentHint:
            "The service comment will be visible in the service history",
          commentPlaceholder: "Enter service comment",
          confirm: {
            description:
              "Are you sure you want to do a service reset on machine <strong>{0}</strong>?",
            note: "Note: This action cannot be undone.",
            title: "Confirm Service Reset"
          },
          description:
            "This will send a service reset command to the machine and works the same as a service reset using the Brokk Config Tool.",
          disabled:
            "The service reset feature is currently not enabled on this machine.",
          errors: {
            notAvailable:
              "A service reset can be performed 100 hours before the scheduled service or when a yearly service is pending.",
            offline: "The machine must be online for a service to be reset.",
            waitingForYearly:
              "The yearly service has not yet been activated on the machine. It may take an hour until it becomes active. Please wait and try again later."
          },
          success: {
            description:
              "The service on machine <strong>{0}</strong> has been successfully reset!",
            title: "Service Reset Successful"
          },
          title: "Service reset"
        },
        schedule: {
          dataTable: {
            headers: {
              comment: "Comment",
              loggedHours: "Logged Hours",
              scheduled: "Scheduled",
              service: "Service",
              serviceDate: "Service Date",
              status: "Status"
            }
          },
          description:
            "The service schedule is based upon the operation time and last service date of the machine. Because the working conditions can vary, it may be necessary to adapt the service interval to the relevant working conditions and environment.",
          received: "Received",
          title: "Service schedule"
        },
        update: "The service was successfully updated"
      },
      serviceAgreement: {
        actions: {
          add: "Add new service agreement",
          edit: "Edit service agreement"
        },
        labels: {
          description: "Description",
          endDate: "End date",
          filename: "File name",
          fileuploadLabel: "Service agreement document",
          startDate: "Start date",
          uploadFilePlaceholder:
            "Upload the document containing specified service agreement"
        },
        messages: {
          added: "New service agreement has been added",
          deleted: "Service agreement has been deleted",
          failedToDownload:
            "Failed to download the service agreement document {file}",
          updated: "Service agreement has been updated"
        },
        title: "Service Agreement",
        warnings: {
          isExpired: "The service agreement is expired"
        }
      }
    },
    manuals: {
      actions: {
        downloadFile: "Download file"
      },
      dataTable: {
        headers: {
          fileName: "File name"
        }
      },
      messages: {
        failedToDownload: "Failed to download {file}"
      }
    },
    measurements: {
      ambient: "Ambient",
      current: "Current",
      electricMotor: "Electric motor",
      electronicUnit: "Electronic unit",
      engineHours: "Engine Hours",
      export: {
        csv: {
          columns: {
            category: "Category",
            description: "Description",
            parameterId: "Parameter ID",
            timestamp: "Timestamp",
            unit: "Unit",
            value: "Value"
          }
        }
      },
      frequency: "Frequency",
      gateway: "Gateway",
      highPressureTime: "High pressure time",
      highTempTime: "High temperature time",
      hydraulicFluid: "Hydraulic fluid",
      hydraulicFluidPressure: "Hydraulic fluid pressure",
      power: "Power",
      temperatures: "Temperatures",
      voltage: "Voltage"
    },
    menuItems: {
      alarms: "Alarms",
      dashboard: "Dashboard",
      info: "Info",
      location: "Location",
      maintenance: "Maintenance",
      manuals: "Manuals",
      measurements: "Measurements",
      parameters: "Parameters",
      rental: "Rental",
      system: "System"
    },
    messages: {
      applyChangesPending: "Waiting for device to apply changes",
      changesApplied: "Changes have been applied",
      contactToLearnMore: "To learn more, please contact",
      errorApplyingChanges: "Error applying changes",
      fetchingMachineDetails: "Fetching machine details...",
      machineAdded: "Machine has been added",
      machineDetailsAreBeeingFetched:
        "The machine's details are being fetched. Please wait.",
      machineDoesNotLoadInfo:
        " If the machine does not load, then please go back and manually refresh the machines view.",
      machineOffline: "The machine is offline.",
      machineUpdated: "Machine has been updated",
      unsavedChanges: "Unsaved changes"
    },
    missingMachineImage: "Missing product image",
    notFound: "Machine not found",
    notifications: {
      settings: {
        actions: {
          edit: "Edit notification settings",
          remove: "Remove notification settings",
          send: "Send notification"
        },
        daysBeforeLeaseExpiration: "Days before lease expires",
        daysBeforeNextYearlyService: "Days before next yearly service",
        description:
          "Enter email addresses of people who should receive email notifications.",
        dialogs: {
          sendNotification: {
            confirm:
              "Do you want to send an email notification to {count} recipient ? | Do you want to send an email notification to {count} recipients ?",
            successMessage:
              "Email notification has been sent | Email notifications have been sent"
          }
        },
        engineHoursBeforeLeaseExpires: "Engine hours before lease expires",
        engineHoursBeforeNextService: "Engine hours before next service",
        maxRecipientsHint: "At most 5 email addresses can be added",
        messages: {
          notificationSettingsRemoved: "Notification settings has been removed",
          notificationSettingsSaved: "Notification settings has been saved"
        },
        noLeaseAdded:
          "To add email notifications, please return to the lease tab and specify a lease.",
        norecipientsAdded: "No recipients added yet.",
        recipients: "Recipients",
        selectEmails:
          "Select up to 5 email adressess that should receive notifications",
        sendHint: "Send an email to all recipients now",
        threshold: "Threshold",
        title: "Notification settings"
      }
    },
    parameters: {
      dataTable: {
        headers: {
          category: "Category",
          description: "Description",
          device: "Device",
          id: "Id",
          number: "Number",
          value: "Value"
        }
      },
      dialogs: {
        editParameter: {
          category: "Category",
          messages: {
            cantBeEdited: "This parameter can't be edited",
            changesNeedApproval:
              "Your changes will need approval from an operator before taking effect"
          },
          parameterNo: "Parameter No.",
          updateinterval: "Update Interval"
        }
      }
    },
    props: {
      description: "Description",
      lastSeen: "Last seen",
      name: "Name",
      product: "Product",
      serialNo: "Serial number",
      serviceStatus: "Service Status",
      status: "Status",
      tenant: "Tenant"
    },
    rental: {
      lease: {
        notDefined: {
          description:
            "To add a lease, please press the below button.<br/>This will guide you through the necessary steps to utilize the rental feature on your machine.",
          title: "No lease has been defined"
        },
        notEnabled: {
          description:
            "Machine rental can be purchased as an add-on.<br />To learn more, please contact",
          title: "The rental feature is currently not enabled on this machine."
        },
        notSupportingDate: "This machine only supports lease by Engine Hour",
        settings: {
          actions: {
            add: "Add lease settings",
            edit: "Edit lease settings",
            remove: "Remove lease settings"
          },
          configuredLeaseSettings: "Configured lease settings.",
          confirm: {
            description:
              " Are you sure you want to apply the lease? This may affect the machine's ability to operate. Please ensure that the machine is in a position that will not cause any damage nor harm to people or equipment.",
            title: "Confirm Lease Update"
          },
          description:
            " Impose restrictions on the machine after the specified engine hours have been reached",
          engineHours: "Engine hours",
          engineHoursHover:
            "The last known engine hours reported by the machine",
          headers: {
            active: "Active",
            requested: "Requested"
          },
          leaseEnd: "Lease end",
          leaseSettingsReportedByMachine:
            "Lease settings reported by the machine.",
          leaseStatusDescription: {
            applied: "Changes have been applied",
            notApplicable: "N/A",
            pending: "Waiting for device"
          },
          machineRestrictions: "Machine restrictions",
          messages: {
            emailNotificationHasBeenSent: "Email notification has been sent",
            leaseUpdateRequestHasBeenSent:
              "A lease update request has been sent to the machine. A notification will be sent when it has been applied."
          },
          restrictionTypes: {
            blinkLights: "Blink Lights",
            disableToolA: "Disable tool A",
            reduceMovementSpeedToLvl3:
              "Reduce movement speed to Speed Reduction Level 3",
            stopEngineAfter: "Stop Engine after"
          },
          sendEmailNotificationAboutLeaseChange:
            "Do you want to send an email notification now to inform the operator that lease settings are about to change?",
          title: "Lease or machine control settings"
        }
      }
    },
    service: "Service",
    system: {
      newVersionAvailable: "A new version is available for download.",
      newVersionDetails: "Version {version}, released {releaseDate}.",
      noDataDescription:
        "This is normal when the machine is new and has not yet reported its values. Please check again after a couple of engine hours."
    },
    tabHeaders: {
      alarms: "Alarms",
      events: "Events",
      lease: "Lease",
      location: "Location",
      measurements: "Measurements",
      notifications: "Notifications",
      service: "Service",
      serviceAgreement: "Service Agreement",
      settings: "Settings"
    },
    updated: "Updated {TIME}"
  }
}
