<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-account</v-icon>
          <span class="title accent--text">{{ title }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form ref="userForm" v-model="valid">
          <v-container>
            <!-- Email -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  v-model="user.email"
                  required
                  :disabled="isEditing"
                  :label="`${$t('users.itemAttributes.email')}*`"
                  :placeholder="$t('users.dialogs.edit.emailPlaceholder')"
                  :rules="[validationRules.email]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Name -->
              <v-col cols="12" sm="6" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  v-model="user.firstName"
                  :rules="[validationRules.name]"
                  :label="`${$t('users.dialogs.edit.givenName')}*`"
                  required
                  :disabled="isUpdating"
                  :placeholder="$t('users.dialogs.edit.givenNamePlaceholder')"
                ></v-text-field>
              </v-col>
              <!-- Family name -->
              <v-col cols="12" sm="6" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  v-model="user.lastName"
                  :rules="[validationRules.name]"
                  :label="`${$t('users.dialogs.edit.familyName')}*`"
                  required
                  :disabled="isUpdating"
                  :placeholder="$t('users.dialogs.edit.familyNamePlaceholder')"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Description -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  v-model="user.description"
                  :label="`${$t('users.itemAttributes.description')}*`"
                  :disabled="isUpdating"
                  :placeholder="$t('users.dialogs.edit.descriptionPlaceholder')"
                  :rules="[validationRules.maxLimit]"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Role -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-select
                  class="pl-1 ml-1"
                  v-model="user.roleId"
                  required
                  :label="`${$t('users.itemAttributes.role')}*`"
                  :placeholder="$t('users.dialogs.edit.rolePlaceholder')"
                  :rules="[validationRules.required]"
                  :items="allowedRoles"
                  item-text="name"
                  item-value="roleId"
                  :disabled="disableRoleEdit"
                ></v-select>
              </v-col>
            </v-row>
            <!-- Web shop access -->
            <v-row>
              <v-col v-if="!disableWebShopAccessEdit" cols="12" class="pa-0">
                <v-select
                  class="pl-1 ml-1"
                  v-model="webshopAccess"
                  required
                  :label="`${$t('users.dialogs.edit.webshopAccess')}*`"
                  :rules="[validationRules.required]"
                  :items="allowedWebshopRoles"
                  item-text="name"
                  item-value="name"
                  :disabled="disableWebShopAccessEdit"
                ></v-select>
              </v-col>
              <v-col v-else cols="12" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  v-model="webshopAccess"
                  :label="`${$t('users.dialogs.edit.webshopAccess')}*`"
                  disabled
                  :placeholder="`${$t('users.dialogs.edit.webshopAccess')}*`"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Fleet analytics -->
            <v-row v-if="hasFleetAnalyticsAccessEdit">
              <v-col cols="12" class="pa-0">
                <v-checkbox
                  :disabled="!hasFleetAnalyticsAccessEdit"
                  v-model="hasFleetAnalyticsAccess"
                >
                  <template v-slot:label>
                    <div>
                      {{ $t("users.dialogs.edit.fleetAnalyticsAccess") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- Service portal -->
            <v-row v-if="hasServicePortalAccessEdit">
              <v-col cols="12" class="pa-0">
                <v-checkbox
                  :disabled="!hasServicePortalAccessEdit"
                  v-model="hasServicePortalAccess"
                >
                  <template v-slot:label>
                    <div>
                      {{ $t("users.dialogs.edit.servicePortalAccess") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- Errors  -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-alert
                  v-if="error"
                  :type="error.type"
                  class="ma-0"
                  elevation="2"
                  border="left"
                  colored-border
                  dense
                  dismissible
                >
                  <div>{{ error.message }}</div>
                  <div v-if="error.causedBy" class="subtitle">
                    {{ error.causedBy }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 mb-2"
          :disabled="!valid || isUpdating"
          :loading="isUpdating"
          @click.stop="onSave"
          >{{ $t("common.actions.save") }}</v-btn
        >
        <v-btn
          class="mb-2 grab-cancel-edit-user"
          :disabled="isUpdating"
          @click.stop="onCancel"
          >{{ $t("common.actions.cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"

let validationRules = null

export default {
  name: "UserDialog",
  props: ["user", "show"],

  data() {
    return {
      valid: false,
      validationRules: validationRules,
      isUpdating: false,
      error: null,
      webshopAccess: "",
      hasFleetAnalyticsAccess: null,
      hasServicePortalAccess: null
    }
  },
  async created() {
    if (this.$store.getters.roleMetadata.length == 0) {
      const langCode = this.$store.getters.dataLangCode
      await this.$store.dispatch("loadRoleMetadata", langCode)
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    isEditing() {
      return this.user.uid !== undefined
    },
    title() {
      return this.isEditing
        ? this.$t("users.actions.edit")
        : this.$t("users.actions.add")
    },
    allowedRoles() {
      return this.$store.getters.allowedRoles(this.currentUser.roleId)
    },
    allowedWebshopRoles() {
      return this.$store.getters.allowedWebshopRoles(this.currentUser.roleId)
    },
    currentUser() {
      return this.$store.getters.loggedInUser
    },
    disableRoleEdit() {
      // Disable editing the role for the current user.
      return this.user.uid === this.currentUser.uid || this.isUpdating
    },
    disableWebShopAccessEdit() {
      // The following brokk connect roles are allowed to edit the user's web shop access.
      let isAllowed =
        this.$store.getters.isAdmin ||
        //  Local admin must have been granted Web shop Admin to be able to do modifications
        (this.$store.getters.isLocalAdmin && this.$store.getters.canEditWebShop)
      if (isAllowed) {
        // If the user being edited got web shop admin, then the logged in user must be Brokk Connect admin
        if (this.webshopAccess === "admin" && !this.$store.getters.isAdmin) {
          isAllowed = false
        }
        if (isAllowed) {
          // If the user being edited got a webshop role that we're not allowed to set, the we can't edit it.
          isAllowed =
            this.allowedWebshopRoles.find(
              wsRoleName => wsRoleName === this.webshopAccess
            ) !== undefined
        }
      }
      return !isAllowed
    },
    hasFleetAnalyticsAccessEdit() {
      return this.$store.getters.isAdmin
    },
    hasServicePortalAccessEdit() {
      return this.$store.getters.isAdmin
    }
  },
  watch: {
    show(value) {
      if (value && this.$refs.userForm) {
        // Reset input validation errors when the dialog is shown
        this.$refs.userForm.resetValidation()
      }
      if (value && this.user !== undefined) {
        this.webshopAccess = this.$store.getters.getWebshopAccess(this.user)
        this.hasFleetAnalyticsAccess = this.$store.getters.getReadFleetAnalytics(
          this.user
        )
        this.hasServicePortalAccess = this.$store.getters.getServicePortalAccessForUser(
          this.user
        )
      }
    }
  },
  methods: {
    async onSave() {
      if (!this.$refs.userForm.validate()) {
        return
      }

      const item = {
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        description: this.user.description,
        roleId: this.user.roleId,
        webshopAccess: this.webshopAccess,
        fleetAnalyticsAccess: this.hasFleetAnalyticsAccess,
        servicePortalAccess: this.hasServicePortalAccess
      }

      let url = ""

      if (this.isEditing) {
        // Don't include email, since we don't allow it to be updated.
        delete item.email

        url = `/api/v1/tenants/${this.user.tenantUid}/users/${this.user.uid}`
      } else {
        url = `/api/v1/tenants/${this.user.tenantUid}/users`
      }

      this.error = null

      this.isUpdating = true

      try {
        if (this.isEditing) {
          await fetchClient(this.$i18n).putOrThrow(url, item)
        } else {
          await fetchClient(this.$i18n).postOrThrow(url, item)
        }
        this.$emit("save", { wasAdded: !this.isEditing })
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    onCancel() {
      this.error = null
      this.$emit("cancel", {})
    }
  }
}
</script>
